$hero_page_paragraph_text_color: lawngreen;
$hero_page_heading_text_color: #00008B;
$icon_group_heading_text_color: #00008B;
$hero_page_image_border_color: #0275d8;
$hero_page_btn_primary_bg_color: #0275d8;
$hero_page_btn_hover_bg_color: #fff;

$hero_page_box_shadow: 0.5rem 0.5rem 0 rgba(22, 160, 133, 0.2);
$hero_page_text_shadow: 0.2rem 0.2rem 0 #8A2BE2;
$hero_page_paragraph_text_shadow: 0.1rem 0.1rem 0 #8A2BE2;
$hero_page_border: 0.2rem solid;


.home {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.5rem;
    padding-top: 10rem;

    .image {
        flex: 1 1 45rem;
        flex-wrap: wrap;
        padding-right: 30px;

        img {
            width: 100%;
            border: 4px solid;
            border-color: $hero_page_image_border_color;
            border-radius: 23px;
        }
    }

    .content {
        flex: 1 1 45rem;

        h3 {
            font-size: 4.5rem;
            color: $hero_page_heading_text_color;
            line-height: 1.8;
            text-shadow: $hero_page_text_shadow;
        }

        p {
            font-size: 1.7rem;
            color: $hero_page_heading_text_color;
            line-height: 1.8;
            padding: 1rem 0;
            text-shadow: $hero_page_paragraph_text_shadow;
            text-indent: 50px
        }

        .btn {
            display: inline-block;
            margin-top: 1rem;
            padding: 0.5rem;
            padding-left: 1rem;
            border: $hero_page_border;
            border-color: $hero_page_btn_primary_bg_color;
            border-radius: 0.5rem;
            box-shadow: $hero_page_box_shadow;
            color: $hero_page_btn_primary_bg_color;
            cursor: pointer;
            font-size: 1.7rem;
            background: $hero_page_btn_hover_bg_color;

            span {
                padding: 0.7rem 1rem;
                border-radius: 0.5rem;
                background: $hero_page_btn_primary_bg_color ;
                color: $hero_page_btn_hover_bg_color;
                margin-left: 0.5rem;
            }

            &:hover {
                background: $hero_page_btn_primary_bg_color;
                color: $hero_page_btn_hover_bg_color;

                span {
                    color: $hero_page_btn_primary_bg_color;
                    background: $hero_page_btn_hover_bg_color;
                    margin-left: 1rem;
                }
            }
        }

    }

    .banner_image_div {
        width: 55%;
        height: auto;
        text-align: center;
        // position: absolute;
        bottom: -150px;
        right: 0;
        // z-index: 9;
        left: 0;
        margin: 0 auto;

        img {

            max-width: 100%;
            height: auto;
            vertical-align: top;

        }
    }
}