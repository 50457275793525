$contact_us_primary_color: #0275d8;
$contact_us_heading_text_color: #444;


$contact_us_btn_primary_bg_color: #0275d8;
$contact_us_btn_hover_bg_color: #fff;

$contact_us_box_shadow: 0.5rem 0.5rem 0 rgba(22, 160, 133, 0.2);
$contact_us_text_shadow: 0.4rem 0.4rem 0 rgba(0, 0, 0, 0.2);
$contact_us_border: 0.2rem solid;

.contact_us {
    .heading {
        text-align: center;
        padding-bottom: 2rem;
        text-shadow: var(--contact_us_text_shadow);
        text-transform: uppercase;
        color: $contact_us_heading_text_color;
        font-size: 5rem;
        letter-spacing: 0.4rem;

        span {
            text-transform: uppercase;
            color: $contact_us_primary_color;
        }
    }

    .row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 2rem;

        .image {
            flex: 3 1 45rem;

            img {
                width: 100%;
                border-radius: 10px;
                border: 3px solid;
                border-color: $contact_us_primary_color;
            }
        }

        form {
            flex: 1 1 45rem;
            background: #fff;
            border: $contact_us_border;
            border-color: $contact_us_primary_color;
            box-shadow: $contact_us_box_shadow;
            text-align: center;
            padding: 2rem;
            border-radius: 0.5rem;

            h3 {
                color: $contact_us_heading_text_color;
                padding-bottom: 1rem;
                font-size: 3rem;
            }

            .box {
                width: 100%;
                margin: 0.7rem 0;
                border-radius: 0.5rem;
                // border: $contact_us_border;
                font-size: 2rem;
                color: $contact_us_heading_text_color;
                text-transform: none;
                padding: 1rem;
            }

            .box_select {
                width: 95%;
                margin: 0.7rem 0 auto;
                border-radius: 0.5rem;
                // border: $contact_us_border;
                font-size: 2rem;
                color: $contact_us_heading_text_color;
                text-transform: none;
                padding: 1rem;
                margin-bottom: 50px;
            }

            .btn {
                padding: 1rem 4rem;
                display: inline-block;
                margin-top: 1rem;
                padding: 0.5rem;
                padding-left: 1rem;
                border: $contact_us_border;
                border-radius: 0.5rem;
                box-shadow: $contact_us_box_shadow;
                color: $contact_us_btn_primary_bg_color;
                cursor: pointer;
                font-size: 1.7rem;
                background: $contact_us_btn_hover_bg_color;

                span {
                    padding: 0.7rem 1rem;
                    border-radius: 0.5rem;
                    background: $contact_us_btn_primary_bg_color ;
                    color: $contact_us_btn_hover_bg_color;
                    margin-left: 0.5rem;
                }

                &:hover {
                    background: $contact_us_btn_primary_bg_color;
                    color: $contact_us_btn_hover_bg_color;

                    span {
                        color: $contact_us_btn_primary_bg_color;
                        background: $contact_us_btn_hover_bg_color;
                        margin-left: 1rem;
                    }
                }
            }
        }
    }
}