// $color_1: var(--primary-text-color);
// $color_2: var(--black);
// $color_3: var(--primary-color);
// $color_4: #fff;
// $color_5: var(--primary-menu-text-color);
// $color_6: var(--primary-heading-text-color);
// $color_7: var(--primary-ment-text-hover-color);
// $color_8: red;
// $color_9: var(--light-color);
// $color_10: white;
// $font-family_1: "Poppins", sans-serif;
// $background-color_1: var(--primary-bg-color);
// $background-color_2: red;
// $background-color_3: blue;
// $background-color_4: green;
// $background-color_5: var(--primary-color);
// $background-color_6: var(--secondary-bg-color);


$website_primary_font_family: "Poppins", sans-serif;
$website_primary_bg_color: #fff;
$website_primary_text_color: #444;
@import "https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap";

// :root {
//     --primary-color: #0275d8;
//     --secondary-color: #0275d8;
//     --primary-bg-color: #fff;
//     --secondary-bg-color: #eee;
//     --primary-menu-text-color: #fff;
//     --primary-text-color: #5E5E5E;
//     --primary-heading-text-color: #e61485;
//     --primary-menu-text-color: #0275d8;
//     --primary-ment-text-hover-color: #e61485;
//     --black: #444;
//     --light-color: #777;
//     --box-shadow: 0.5rem 0.5rem 0 rgba(22, 160, 133, 0.2);
//     --text-shadow: 0.4rem 0.4rem 0 rgba(0, 0, 0, 0.2);
//     --border: 0.2rem solid var(--primary-color);
// }

* {
    font-family: $website_primary_font_family;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    border: none;
    transition: all 0.2s ease-out;
    text-decoration: none;
}

html {
    font-size: 62.5%;
    overflow-x: hidden;
    scroll-padding-top: 7rem;
    scroll-behavior: smooth;
}

section {
    padding: 2rem 9%;
    padding-top: 7rem;


    // &:nth-child(even) {
    //     background: $website_primary_text_color;
    // }
}

body {
    background-color: $website_primary_bg_color;
    color: $website_primary_text_color;
}

section {
    // background-color: #fafafa;
    margin-top: 40px;
}

.header-shape {
    height: 980px;
    background: linear-gradient(180deg, #292dc2, #0084e9);
    background-color: rgba(0, 0, 0, 0);
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-attachment: scroll;
    background-image: linear-gradient(rgb(41, 45, 194), rgb(0, 132, 233));
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
}

// .review {
//     .box-container {
//         display: grid;
//         grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));
//         gap: 2rem;

//         .box {
//             border: var(--border);
//             box-shadow: var(--box-shadow);
//             border-radius: 0.5rem;
//             padding: 2.5rem;
//             background: #fff;
//             text-align: center;
//             position: relative;
//             overflow: hidden;
//             z-index: 0;

//             img {
//                 height: 10rem;
//                 width: 10rem;
//                 border-radius: 50%;
//                 object-fit: cover;
//                 border: 0.5rem solid #fff;
//             }

//             h3 {
//                 color: $color_4;
//                 font-size: 2.2rem;
//                 padding: 0.5rem 0;
//             }

//             .stars {
//                 i {
//                     color: $color_4;
//                     font-size: 1.5rem;
//                 }
//             }

//             .text {
//                 color: $color_9;
//                 line-height: 1.8;
//                 font-size: 1.6rem;
//                 padding-top: 4rem;
//             }

//             &::before {
//                 content: "";
//                 position: absolute;
//                 top: -4rem;
//                 left: 50%;
//                 transform: translateX(-50%);
//                 background: var(--primary-color);
//                 border-bottom-left-radius: 50%;
//                 border-bottom-right-radius: 50%;
//                 height: 25rem;
//                 width: 120%;
//                 z-index: -1;
//             }
//         }
//     }
// }

// .blogs {
//     .box-container {
//         display: grid;
//         grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
//         gap: 2rem;

//         .box {
//             border: var(--border);
//             box-shadow: var(--box-shadow);
//             border-radius: 0.5rem;
//             padding: 2rem;

//             .image {
//                 height: 20rem;
//                 overflow: hidden;
//                 border-radius: 0.5rem;

//                 img {
//                     height: 100%;
//                     width: 100%;
//                     object-fit: cover;
//                 }
//             }

//             &:hover {
//                 .image {
//                     img {
//                         transform: scale(1.2);
//                     }
//                 }
//             }

//             .content {
//                 padding-top: 1rem;

//                 .icon {
//                     padding: 1rem 0;
//                     display: flex;
//                     align-items: center;
//                     justify-content: space-between;

//                     a {
//                         font-size: 1.4rem;
//                         color: $color_9;

//                         &:hover {
//                             color: $color_3;
//                         }

//                         i {
//                             padding-right: 0.5rem;
//                             color: $color_3;
//                         }
//                     }
//                 }

//                 h3 {
//                     color: $color_2;
//                     font-size: 3rem;
//                 }

//                 p {
//                     color: $color_9;
//                     font-size: 1.5rem;
//                     line-height: 1.8;
//                     padding: 1rem 0;
//                 }
//             }
//         }
//     }
// }




@media (max-width: 991px) {
    html {
        font-size: 55%;
    }

    section {
        padding: 2rem;
    }
}



@media (max-width: 450px) {
    html {
        font-size: 55%;
    }
}