$features_paragraph_text_color: #8A2BE2;
$features_heading_text_color: #00008B;
$features_font_awesome_icon_color: #8A2BE2;
$features_box_shadow: 0.5rem 0.5rem 0 rgba(22, 160, 133, 0.2);
$features_text_shadow: 0.4rem 0.4rem 0 rgba(0, 0, 0, 0.2);
$features_border: 0.2rem solid var(--hero_page_image_border_color);



.features {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    padding-top: 5rem;
    padding-bottom: 5rem;


    // background: linear-gradient(180deg, #292dc2, #0084e9);
    // background-color: rgba(0, 0, 0, 0);
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-attachment: scroll;
    // background-image: linear-gradient(rgb(41, 45, 194), rgb(0, 132, 233));
    background: rgb(0, 132, 233);
    // background: linear-gradient(180deg, rgba(0, 132, 233, 0.18109250536151966) 0%, rgba(250, 250, 250, 0) 100%);
    background: linear-gradient(180deg, rgba(250, 250, 250, 0) 0%, rgba(0, 132, 233, 0.18109250536151966) 100%);
    // position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;




    .icons {
        border: var(--features_border);
        box-shadow: var(--features_box_shadow);
        border-radius: 0.5rem;
        text-align: center;
        padding: 2.5rem;

        i {
            font-size: 4.5rem;
            color: $features_font_awesome_icon_color;
            padding-bottom: 0.7rem;
        }

        h3 {
            font-size: 4.5rem;
            color: $features_heading_text_color;
            padding: 0.5rem 0;
            text-shadow: var(--features_text_shadow);
        }

        p {
            font-size: 1.7rem;
            color: $features_paragraph_text_color;
        }
    }
}