$header-bg-color: #fff;
$header-menu-seconday-text-color: #0275d8;
$header-menu-primary-text-color: #fff;

$header-menu-primary-color: #0275d8;
$header-menu-text-hover-color: #e61485;

$header-secondary-bg-color: #eee;

$box-shadow: 0.5rem 0.5rem 0 rgba(22, 160, 133, 0.2);
$border: 0.2rem solid var(--header-menu-primary-text-color);




.header {
    padding: 2rem 9%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background: $header-bg-color;

    .logo {
        font-size: 2.5rem;
        color: $header-bg-color;
        font-weight: bolder;

        .logo-img-div {
            height: 50px;
            display: flex;
            align-items: center;

            span {
                img {
                    overflow: hidden;
                    width: 120px;
                    background-size: cover;
                    padding-top: 25px;
                }

                .logo-txt-span {
                    font-size: 2.5rem;
                    color: $header-menu-primary-text-color;
                    text-transform: uppercase;
                }
            }
        }

        i {
            color: $header-menu-primary-text-color;
            padding: 8px;
        }
    }

    .navbar {
        a {
            font-size: 1.7rem;
            color: $header-menu-primary-text-color;
            font-weight: bolder;
            margin-left: 2rem;

            &:hover {
                color: $header-menu-text-hover-color;
                border-bottom: 3px solid;
                border-bottom-color: $header-menu-text-hover-color;
            }
        }

        .active {
            color: $header-menu-text-hover-color !important;
            border-bottom: 3px solid !important;
            border-bottom-color: $header-menu-text-hover-color !important;
        }
    }

    .menu-btn {
        font-size: 2.5rem;
        border-radius: 0.5rem;
        background: $header-menu-primary-text-color;
        color: $header-menu-primary-color;
        padding: 1rem 1.5rem;
        cursor: pointer;
        display: none;
    }

}


.header_blue {
    padding: 2rem 9%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $header-menu-seconday-text-color;

    .logo {
        font-size: 2.5rem;
        color: $header-bg-color;
        font-weight: bolder;

        .logo-img-div {
            height: 50px;
            display: flex;
            align-items: center;

            span {
                img {
                    overflow: hidden;
                    width: 120px;
                    background-size: cover;
                    padding-top: 25px;
                }

                .logo-txt-span {
                    font-size: 2.5rem;
                    color: $header-menu-primary-text-color;
                    text-transform: uppercase;
                }
            }
        }

        i {
            color: $header-menu-primary-text-color;
            padding: 8px;
        }
    }

    .navbar {
        a {
            font-size: 1.7rem;
            color: $header-menu-primary-text-color;
            font-weight: bolder;
            margin-left: 2rem;

            &:hover {
                color: $header-menu-text-hover-color;
                border-bottom: 3px solid;
                border-bottom-color: $header-menu-text-hover-color;
            }
        }

        .active {
            color: $header-menu-text-hover-color !important;
            border-bottom: 3px solid !important;
            border-bottom-color: $header-menu-text-hover-color !important;
        }
    }

    .menu-btn {
        font-size: 2.5rem;
        border-radius: 0.5rem;
        background: $header-secondary-bg-color;
        color: $header-menu-primary-color;
        padding: 1rem 1.5rem;
        cursor: pointer;
        display: none;
    }

}

@media (max-width: 768px) {}

@media (max-width: 991px) {


    .header {
        padding: 2rem;
    }


}

@media (max-width: 768px) {
    #menu-btn {
        display: initial;
    }

    .header {
        .navbar {
            position: absolute;
            top: 115%;
            right: 2rem;
            border-radius: 0.5rem;
            box-shadow: var(--box-shadow);
            width: 30rem;
            border: var(--border);
            background: #fff;
            transform: scale(0);
            opacity: 0;
            transform-origin: top right;
            transition: none;

            a {
                font-size: 2rem;
                display: block;
                margin: 2.5rem;
            }
        }

        .navbar.active {
            transform: scale(1);
            opacity: 1;
            transition: 0.2s ease-out;
            background-color: $header-secondary-bg-color;
        }
    }
}