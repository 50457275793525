$about_us_primary_color: #0275d8;
$about_us_text_shadow: 0.4rem 0.4rem 0 rgba(0, 0, 0, 0.2);
$about_us_heading_text_color: #444;
$about_us_text_color: #777;

$about_us_btn_primary_bg_color: #0275d8;
$about_us_btn_hover_bg_color: #fff;


$about_us_box_shadow: 0.5rem 0.5rem 0 rgba(22, 160, 133, 0.2);
$about_us_text_shadow: 0.4rem 0.4rem 0 rgba(0, 0, 0, 0.2);
$about_us_border: 0.2rem solid;

// .image_scale_dowm {
//     object-fit: scale-down !important;
// }

.about {

    .heading {
        text-align: center;
        padding-bottom: 2rem;
        text-shadow: var(--about_us_text_shadow);
        text-transform: uppercase;
        color: $about_us_heading_text_color;
        font-size: 5rem;
        letter-spacing: 0.4rem;

        span {
            text-transform: uppercase;
            color: $about_us_primary_color;
        }
    }

    .row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 2rem;

        .image {
            flex: 1 1 45rem;
            padding-bottom: 11px;
            padding-right: 82px;
            object-fit: contain;

            img {
                width: 100%;
                border-radius: 57px;
                border: 6px solid;
                border-color: $about_us_primary_color;
            }
        }

        .content {
            flex: 1 1 45rem;

            h3 {
                color: $about_us_heading_text_color;
                text-shadow: $about_us_text_shadow;
                font-size: 4rem;
                line-height: 1.8;
            }

            p {
                color: $about_us_text_color;
                padding: 1rem 0;
                font-size: 1.5rem;
                line-height: 1.8;
            }

            .btn {
                padding: 1rem 4rem;
                display: inline-block;
                margin-top: 1rem;
                padding: 0.5rem;
                padding-left: 1rem;
                border: $about_us_border;
                border-color: $about_us_primary_color;
                border-radius: 0.5rem;
                box-shadow: $about_us_box_shadow;
                color: $about_us_btn_primary_bg_color;
                cursor: pointer;
                font-size: 1.7rem;
                background: $about_us_btn_hover_bg_color;

                span {
                    padding: 0.7rem 1rem;
                    border-radius: 0.5rem;
                    background: $about_us_btn_primary_bg_color ;
                    color: $about_us_btn_hover_bg_color;
                    margin-left: 0.5rem;
                }

                &:hover {
                    background: $about_us_btn_primary_bg_color;
                    color: $about_us_btn_hover_bg_color;

                    span {
                        color: $about_us_btn_primary_bg_color;
                        background: $about_us_btn_hover_bg_color;
                        margin-left: 1rem;
                    }
                }
            }
        }
    }
}