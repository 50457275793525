$black: #444;
$light-color: #777;
$primary-color: #0275d8;

.footer {
    position: relative;

    .box-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
        gap: 2rem;

        .box {
            h3 {
                font-size: 2.5rem;
                color: $black;
                padding: 1rem 0;
            }

            a {
                display: block;
                font-size: 1.5rem;
                color: $light-color;
                padding: 1rem 0;

                i {
                    padding-right: 0.5rem;
                    color: $primary-color;
                }

                &:hover {
                    i {
                        padding-right: 2rem;
                    }
                }
            }
        }
    }


}