$staff_primary_color: #0275d8;
$staff_heading_text_color: #444;
$staff_link_hover_color: #fff;

$staff_box_shadow: 0.5rem 0.5rem 0 rgba(22, 160, 133, 0.2);
$staff_text_shadow: 0.4rem 0.4rem 0 rgba(0, 0, 0, 0.2);
$staff_border: 0.2rem solid;

.staffs {
    .heading {
        text-align: center;
        padding-bottom: 2rem;
        text-shadow: var(--staff_text_shadow);
        text-transform: uppercase;
        color: $staff_heading_text_color;
        font-size: 5rem;
        letter-spacing: 0.4rem;

        span {
            text-transform: uppercase;
            color: $staff_primary_color;
        }
    }

    .box-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
        gap: 2rem;

        .box {
            text-align: center;
            background: #fff;
            border-radius: 0.5rem;
            border: $staff_border;
            border-color: $staff_primary_color;
            // box-shadow: $staff_box_shadow;
            padding: 2rem;

            img {
                height: 20rem;
                border: $staff_border;
                border-radius: 0.5rem;
                margin-top: 1rem;
                margin-bottom: 1rem;
            }

            h3 {
                color: $staff_heading_text_color;
                font-size: 2.5rem;
            }

            span {
                color: $staff_primary_color;
                font-size: 1.5rem;
            }

            .share {
                padding-top: 2rem;

                a {
                    height: 5rem;
                    width: 5rem;
                    line-height: 4.5rem;
                    font-size: 2rem;
                    color: $staff_primary_color;
                    border-radius: 0.5rem;
                    border: $staff_border;
                    margin: 0.3rem;

                    &:hover {
                        background: $staff_primary_color;
                        color: $staff_link_hover_color;
                        box-shadow: $staff_box_shadow;
                    }
                }
            }
        }
    }
}