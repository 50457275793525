/* scroll to top button */
$scroll_to_top_primary_color: #0275d8;

.top-btn-position {
    position: fixed;
    bottom: 15px;
    right: 20px;
}

.top-btn-style {
    transform: scale(2.5);
    height: 150px;
    width: 150px;
    border-radius: 50%;
    font-size: 24px !important;
    border: 2px solid;
    border-color: $scroll_to_top_primary_color;
    background-color: #fff;
    color: $scroll_to_top_primary_color;
    cursor: pointer;
}

.top-btn-style:hover {
    animation: none;
    background-color: $scroll_to_top_primary_color;
    color: #fff;
    border: 2px solid #fff;
}