$footer-primary-color: #0275d8;
$footer-copy-right-company-name-text-color: #e61485;
$footer-copy-right-text-color: white;
$footer-font-family: "Poppins", sans-serif;

footer {
    background-color: $footer-primary-color;
    color: $footer-copy-right-text-color;

    // .copyrights {
    //     font-family: $footer-font-family;
    //     text-align: center;
    //     height: 14vh;
    //     font-size: 14px;
    //     padding-top: 30px;
    //     background-color: $footer-primary-color;
    // }

    // span {
    //     color: $footer-copy-right-company-name-text-color;
    //     font-weight: bolder;
    // }

    .copyrights {
        // background-color: #7c4754;
        background-color: #00008B;
        // color: #fff;
        color: #fff;
        padding: 15px 30px;
        text-align: center;
        // height: 80px;

        .copyrights-wrapper {
            margin-left: auto;
            margin-right: auto;
            max-width: 1200px;
        }

        .copyrights-text {
            color: #fff;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            margin-bottom: 0;
            margin-top: 0;
        }

        .copyrights-link {
            color: #fff;
            text-decoration: none;
        }
    }






}