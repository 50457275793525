$courses_text_color : #777;
$courses_primary_color : #0275d8;
$courses_heading_text_color : #444;
$courses_font_awesome_icon_color : #0275d8;

$courses_btn_primary_bg_color: #0275d8;
$courses_btn_hover_bg_color: #fff;

$courses_box_shadow: 0.5rem 0.5rem 0 rgba(22, 160, 133, 0.2);
$courses_text_shadow: 0.4rem 0.4rem 0 rgba(0, 0, 0, 0.2);

$courses_border: 0.2rem solid;

.courses {
    .heading {
        text-align: center;
        padding-bottom: 2rem;
        text-shadow: var(--courses_text_shadow);
        text-transform: uppercase;
        color: $courses_heading_text_color;
        font-size: 5rem;
        letter-spacing: 0.4rem;

        span {
            text-transform: uppercase;
            color: $courses_primary_color;
        }
    }

    .box-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));
        gap: 2rem;

        .box {
            background: #fff;
            border-radius: 0.5rem;
            box-shadow: $courses_box_shadow;
            border: $courses_border;
            border-color: $courses_primary_color;
            padding: 2.5rem;

            i {
                color: $courses_font_awesome_icon_color;
                font-size: 5rem;
                padding-bottom: 0.5rem;
            }

            h3 {
                color: $courses_heading_text_color;
                font-size: 2.5rem;
                padding: 1rem 0;
            }

            p {
                color: $courses_text_color;
                font-size: 1.4rem;
                line-height: 2;
            }

            .btn {
                display: inline-block;
                margin-top: 1rem;
                padding: 0.5rem;
                padding-left: 1rem;
                border: $courses_border;
                border-radius: 0.5rem;
                box-shadow: $courses_box_shadow;
                color: $courses_btn_primary_bg_color;
                cursor: pointer;
                font-size: 1.7rem;
                background: $courses_btn_hover_bg_color;
                ;

                span {
                    padding: 0.7rem 1rem;
                    border-radius: 0.5rem;
                    background: $courses_btn_primary_bg_color ;
                    color: $courses_btn_hover_bg_color;
                    margin-left: 0.5rem;
                }

                &:hover {
                    background: $courses_btn_primary_bg_color;
                    color: $courses_btn_hover_bg_color;

                    span {
                        color: $courses_btn_primary_bg_color;
                        background: $courses_btn_hover_bg_color;
                        margin-left: 1rem;
                    }
                }
            }
        }
    }
}